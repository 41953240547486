<template>
  <EventTemplate
    :headerProps="headerProps"
    :mediaInfo="mediaInfo"
    :content="content"
    :events="state.event.events"
    @onClick="onClick"
  />
</template>

<script>
import EventTemplate from "@/components/templates/Event/Event";
import { headerProps } from "@/components/constants";
import heroImg from "@/assets/images/sample-hero.jpg";

export default {
  components: {
    EventTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Event",
      },
    };
  },
  async created() {
    await this.actions.event.getEvents({where: {type: 'CALENDAR'}})
    console.log(this.state.event.events)
  },
  methods: {
    onClick(event) {
      this.$router.push({name: 'Scheduling', query: {eventId: event.id, ...this.$route.query}})
    }
  },
};
</script>

<style scoped>
</style>
