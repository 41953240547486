<template>
    <HeaderSection v-bind="headerProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<div class="grid grid-cols-1 sm:grid-cols-3 gap-6">
                    <div @click="$emit('onClick', event)" v-for="event in events" :key="event.id" class="border-2 rounded-md px-3 py-3 group hover:border-indigo-700 cursor-pointer">
                        <Text size="xl" weight="semibold" custom-class="group-hover:text-indigo-700" color="gray-700" :content="event.name"/>
                        <Text size="" weight="semibold" custom-class="group-hover:text-indigo-500" color="gray-500" :content="`${event?.duration?.split('M')?.[1]} mins, ${event.subtype}`"/>
                        <Text size="sm" color="gray-500" custom-class="mt-4 group-hover:text-indigo-500" :content="`${event?.creator?.firstName} ${event?.creator?.lastName}`"/>
                    </div>
                </div>
                <!-- <Button type="submit" @click="submit" class="button-filled button-color submit-btn" size="size" variant="abc">Complete Booking</Button> -->
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import moment from 'moment';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
import Text from '../../atoms/Text/Text.vue'
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, Carousel, CopyRight, Text
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Object,
            default: () => null
        },
        content: {
            type: Object,
            default: () => null
        },
        events: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            footerProps,
            moment: moment,
            depositStates: [
                {label: "I have toured the venue already and ready to put a deposit to hold my date(s)", value: "toured"},
                {label: "I would like to place a soft hold and schedule a tour before placing a deposit", value: "soft_hold"},
                {label: "I have not toured the venue, but I am ready to put a deposit to hold my date(s)", value: "not_toured"},
            ],
        }
    },
    methods: {
        submit() {
            this.$router.push("/booking_payment");
        }
    },
}
</script>

<style>

</style>